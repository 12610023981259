import React, { useEffect, useState } from "react";
import { Provider, useDispatch } from "react-redux";
import GlobalStyles from "@iso/assets/styles/globalStyle";
import Snackbar from "@iso/Mcomponents/extended/Snackbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useSelector } from "react-redux";

import { store } from "./redux/store";
import Boot from "./redux/boot";
import Routes from "./router";
import {
  jssPreset,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core/styles";
import AppProvider from "./AppProvider";
import theme from "./themes";
import IntlMessages from "@iso/components/utility/intlMessages";
import { Toolbar } from "@material-ui/core";
import { getTokenB, onMessageListener } from "./library/firebase/firebase";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import instance from "./library/helpers/axios";
import useSound from "use-sound";
import notify from "../src/sounds/resume.wav";

// import { StylesProvider } from "@material-ui/styles";
//import { analytics } from "./library/firebase/firebase";
String.prototype.cheakEmpty = function (returned) {
  let selectedString = this;

  if (selectedString) return selectedString;
  else return returned;
};
String.prototype.translate = function (returned) {
  return <IntlMessages id={this} />;
};

const Customize = ({ children }) => {
  const customization = useSelector((state) => state.customizationReducer);
  const [isTokenFound, setTokenFound] = React.useState(false);
  const isLogged = useSelector((state) => state.Auth?.idToken);
  const [play] = useSound(notify);
  const [actionType, setActionType] = useState(null);
  const dispatch = useDispatch();
  const [runner, setRunner] = useState(false);

  useEffect(() => {
    console.log("MSG listner");
    onMessageListener()
      .then((payload) => {
        setRunner((old) => !old);
        console.log("hi notify");
        console.log({ payload }, "inner");
        // check is loged?
        setActionType(payload.data?.action_type);
        localStorage.removeItem("action-type");
        localStorage.setItem("action-type", payload.data?.action_type);
        console.log({ test: payload.data.action_type });
        dispatch({
          type: "SET_NOTIFICATION_ACTION",
          payload: payload.data?.action_type,
        });

        toast.info(
          <>
            <h3>{payload.notification.title}</h3>
            <p>{payload.notification.body}</p>
          </>
        );
        play();
      })
      .catch((err) => {});
  }, [runner]);
  const profile = JSON.parse(localStorage.getItem("profile"));
  const userId = profile?.id;
  console.log({ profile });
  const sendToken = (token) => {
    instance
      .post(
        `/notifications/submit_token/${userId}`,
        { token },
        {
          headers: {
            Authorization: `Bearer ${isLogged}`,
          },
        }
      )
      .then((res) => {});
  };

  React.useEffect(() => {
    if (isLogged) {
      getTokenB(setTokenFound);
      if (isTokenFound) {
        sendToken(isTokenFound);
      }
    }
  }, [isTokenFound]);
  return (
    <>
      <GlobalStyles />
      <StylesProvider>
        <ThemeProvider theme={theme(customization)}>
          <CssBaseline />

          <Routes />
          <ToastContainer />
        </ThemeProvider>
      </StylesProvider>
    </>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <AppProvider>
        <>
          <Customize />
        </>
      </AppProvider>
    </Provider>
  );
};
if (localStorage.getItem("lang") == "ar") {
  document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
} else {
}

Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
